//libraries
import { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//custom hooks
import { setLanguage } from "./context/langSlices/langReducers";

//config
import locale_privacy from "./config/locales/locale.privacy";
import locale_routes from "./config/locales/locale.routes";
import CookieConsent from "react-cookie-consent";

//temp imports
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";

import Homepage from "./pages/Homepage/Homepage";
import AllProductsPage from "./pages/AllProductsPage/AllProductsPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import GalleryPage from "./pages/GalleryPage/GalleryPage";

import PrivacyPageHU from "./pages/PrivacyPolicy/PrivacyPolicyHU";
import PrivacyPageAT from "./pages/PrivacyPolicy/PrivacyPolicyAT";

import PageNotFound from "./pages/PageNotFound/PageNotFound";
//layout
// const Header = lazy(() => import("./layout/Header/Header"));
// const Footer = lazy(() => import("./layout/Footer/Footer"));
// //pages
// const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
// const AllProductsPage = lazy(() =>
//   import("./pages/AllProductsPage/AllProductsPage")
// );
// const ProductPage = lazy(() => import("./pages/ProductPage/ProductPage"));
// const ContactPage = lazy(() => import("./pages/ContactPage/ContactPage"));
// const AboutUsPage = lazy(() => import("./pages/AboutUsPage/AboutUsPage"));
// const GalleryPage = lazy(() => import("./pages/GalleryPage/GalleryPage"));

//components
import Loader from "./components/Loader/Loader";

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);
  const { pathname } = useLocation();
  const location = pathname.split("/")[1];

  useEffect(() => {
    const newLang = location === "at" ? "at" : "hu";
    dispatch(setLanguage(newLang));
  }, [location, dispatch]);

  // useEffect(() => {
  //   dispatch(resetProduct());
  // }, [location]);

  //path arrays
  const homepage_url_array = Object.values(locale_routes.homepage_url);
  const products_url_array = Object.values(locale_routes.products_url);
  const contact_url_array = Object.values(locale_routes.contact_page_url);
  const about_us_url_array = Object.values(locale_routes.about_us_url);
  const gallery_url_array = Object.values(locale_routes.gallery_url);

  //test routes
  const legvarak_v2_url_array = Object.values(locale_routes.legvarak_v2_url);

  return (
    <>
      <Header lang={lang} />
      <CookieConsent
        location="bottom"
        buttonText={locale_privacy.accept_btn[lang]}
        ariaAcceptLabel={locale_privacy.accept_btn[lang]}
        cookieName="<cookieName>"
        style={{
          background: "#F05821",
          color: "#333",
          justifyContent: "center",
        }}
        buttonStyle={{
          color: "#333",
          backgroundColor: "#ffffff",
          fontSize: "16px",
          fontWeight: "700",
          borderRadius: "5px",
          padding: "10px 10px",
        }}
        expires={150}
      >
        <span>{locale_privacy.main_text[lang]}</span>
        <span>
          <a
            style={{
              color: "#333",
              fontWeight: "500",
              marginLeft: "5px",
            }}
            href={`/${locale_routes.privacy_url[lang]}`}
          >
            {locale_privacy.info_link_text[lang]}
          </a>
        </span>
      </CookieConsent>
      <Routes>
        {products_url_array.map((langi) => (
          <Route
            key="ProdBySlug"
            path={langi + "/:slug"}
            element={<ProductPage lang={lang} />}
          />
        ))}

        {products_url_array.map((langi) => (
          <Route
            key="Products"
            path={langi}
            element={<AllProductsPage lang={lang} />}
          />
        ))}

        <Route
          key="PrivacyPageHU"
          path={"/adatvedelem"}
          element={<PrivacyPageHU lang={lang} />}
        />
        <Route
          key="PrivacyPageAT"
          path={"/at/datenschutz"}
          element={<PrivacyPageAT lang={lang} />}
        />

        {about_us_url_array.map((langi) => (
          <Route
            key="AboutUsPage"
            path={langi}
            element={<AboutUsPage lang={lang} />}
          />
        ))}

        {contact_url_array.map((langi) => (
          <Route
            key="ContactPage"
            path={langi}
            element={<ContactPage lang={lang} />}
          />
        ))}

        {gallery_url_array.map((langi) => (
          <Route
            key="GalleryPage"
            path={langi}
            element={<GalleryPage lang={lang} />}
          />
        ))}

        {homepage_url_array.map((langi) => (
          <Route key="Home" path={langi} element={<Homepage lang={lang} />} />
        ))}
        <Route path="*" element={<PageNotFound lang={lang} />} />
      </Routes>
      <Footer lang={lang} />
    </>
  );
};

export default App;
